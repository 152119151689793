import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import TimeOfDayFilterFlat from "components/Content/AdvancedFilters/AdvancedFiltersFlat/TimeOfDayFilterFlat";
import { useContext } from "react";
import { ThemeContext } from "theme";

interface TimeOfDayFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const TimeOfDayFilter = ({
  setSearchFilters,
  searchFilters,
}: TimeOfDayFilterProps) => {
  const reset = () => () => {};
  const info = (
    <p>
      THIS FILTER DIVIDE A DAY INTO FOUR DIFFERENT PARTS, WHICH DON’T TELL US
      THE EXACT TIME; INSTEAD, THEY REFER TO AN INTERVAL OF THE DAY
    </p>
  );
  const { mainColor } = useContext(ThemeContext);
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      title="TIME OF THE DAY"
      isComingSoon
    >
      <TimeOfDayFilterFlat accentColor={mainColor} />
    </AdvancedFilterWrapper>
  );
};

export default TimeOfDayFilter;
