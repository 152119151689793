export const MicroIcon = () => (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_1160)">
      <path
        d="M3.9973 0C5.25874 0 6.28171 1.0206 6.28171 2.27913V6.9596C6.28171 8.21812 5.25874 9.23872 3.9973 9.23872C2.73586 9.23872 1.71289 8.21812 1.71289 6.9596V2.27913C1.71289 1.0206 2.73586 0 3.9973 0Z"
        fill="#707070"
      />
      <path
        d="M7.23636 6.87906C7.23636 8.66284 5.78726 10.1086 3.99805 10.1086C2.20883 10.1086 0.761036 8.66284 0.761036 6.87906V4.87036H0V6.87906C0.00130314 8.93457 1.56638 10.6546 3.61753 10.8536V12.2395H1.20932V13.0001H6.78808V12.2395H4.37856V10.8536C6.43101 10.6559 7.9987 8.93587 8 6.87906V4.87036H7.23636V6.87906Z"
        fill="#707070"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_1160">
        <rect width="8" height="13" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
