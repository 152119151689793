import api from "../api";

// Types
export interface Credentials {
  email: string;
  password: string;
}

interface LoginResponse {
  idToken: string;
  refreshToken: string;
  expiresIn: string;
}

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

export const loginUser = async (
  credentials: Credentials
): Promise<LoginResponse> => {
  const params = new URLSearchParams();
  params.append("email", credentials.email);
  params.append("password", credentials.password);

  const response = await api.post<LoginResponse>("/login", params, config);
  const token = response.data.idToken;
  const refreshToken = response.data.refreshToken;
  localStorage.setItem("jwt", token);
  localStorage.setItem("refreshToken", refreshToken);
  setAuthToken(token);
  return response.data;
};

export const logoutUser = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("refreshToken");
  setAuthToken('');
};

export const refreshAuthToken = async (): Promise<string | null> => {
  try {
    const storedRefreshToken = localStorage.getItem("refreshToken");
    if (!storedRefreshToken) {
      return null;
    }

    const response = await api.post(
      "/refresh-token",
      {
        refreshToken: storedRefreshToken,
      },
      config
    );

    const newToken = response.data.idToken;
    localStorage.setItem("jwt", newToken);
    setAuthToken(newToken);

    return newToken;
  } catch (error) {
    return null;
  }
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const newToken = await refreshAuthToken();
      if (newToken) {
        originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
        return api(originalRequest);
      }
    }

    return Promise.reject(error);
  }
);

export const setAuthToken = (token: string | null): void => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common["Authorization"];
  }
};

const storedToken: string | null = localStorage.getItem("jwt");
if (storedToken) setAuthToken(storedToken);
