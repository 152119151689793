const TimelineCusorIcon = () => (
  <svg
    width="4"
    height="47"
    viewBox="0 0 4 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.057"
      y="0.974727"
      width="1.57104"
      height="45.0382"
      fill="white"
      stroke="#FCFCFC"
      strokeWidth="1.57104"
    />
  </svg>
);

export default TimelineCusorIcon;
