const DownloadIcon = () => (
  <svg
    width="25"
    height="23"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.1"
      y="0.1"
      width="11.8891"
      height="11.8"
      stroke="white"
      strokeWidth="0.2"
    />
    <path
      d="M5.78787 7.21213C5.90503 7.32929 6.09497 7.32929 6.21213 7.21213L8.12132 5.30294C8.23848 5.18579 8.23848 4.99584 8.12132 4.87868C8.00416 4.76152 7.81421 4.76152 7.69706 4.87868L6 6.57574L4.30294 4.87868C4.18579 4.76152 3.99584 4.76152 3.87868 4.87868C3.76152 4.99584 3.76152 5.18579 3.87868 5.30294L5.78787 7.21213ZM5.7 2V7H6.3V2H5.7Z"
      fill="#F7F7F7"
    />
    <line x1="3" y1="8.7" x2="9" y2="8.7" stroke="white" strokeWidth="0.6" />
  </svg>
);

export default DownloadIcon;
