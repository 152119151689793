import React, { useContext } from "react";
import { ThemeContext } from "theme";

interface AdvancedFilterProps {
  filterValue: string;
  filterName: string;
  icon: React.ReactNode;
  className?: string;
}

const AdvancedFilter = ({
  filterValue,
  filterName,
  icon,
  className,
}: AdvancedFilterProps) => {
  const { mainColor } = useContext(ThemeContext);
  return (
    <div className="flex items-center gap-2 border-r border-white p-3 h-full">
      <span className="material-symbols-outlined text-white">{icon}</span>
      <div className="flex flex-col items-start">
        <p className="text-xs text-[#848484]">{filterName}</p>
        <p
          style={{ color: mainColor }}
          className={`text-xs whitespace-nowrap overflow-hidden overflow-ellipsis text-left ${className}`}
        >
          {filterValue}
        </p>
      </div>
    </div>
  );
};

export default AdvancedFilter;
