export const SearchIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      r="8.16805"
      transform="matrix(0.707107 0.707107 0.707107 -0.707107 10.8443 10.8442)"
      stroke="#F5F5F5"
    />
    <line x1="16.9903" y1="17.0261" x2="23.611" y2="23.6468" stroke="#F5F5F5" />
  </svg>
);
