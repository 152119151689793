import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import { useContext } from "react";
import { ThemeContext } from "theme";

interface DurationFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const DurationFilter = ({
  setSearchFilters,
  searchFilters,
}: DurationFilterProps) => {
  const reset = () => () => {};
  const info = (
    <p>
      THIS FILTER DIVIDE A DAY INTO FOUR DIFFERENT PARTS, WHICH DON’T TELL US
      THE EXACT TIME; INSTEAD, THEY REFER TO AN INTERVAL OF THE DAY
    </p>
  );
  const { mainColor } = useContext(ThemeContext);
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      title="DURATION"
      isComingSoon
    >
      <div className="flex flex-col">
        <p style={{ color: mainColor }} className="text-[40px]">
          00 : 00 : 00
        </p>
        <div className="flex gap-10">
          <p className="text-[10px] ml-2">HOURS</p>
          <p className="text-[10px] ml-1">MINUTES</p>
          <p className="text-[10px]">SECONDS</p>
        </div>
      </div>
    </AdvancedFilterWrapper>
  );
};

export default DurationFilter;
