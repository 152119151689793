import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import { useContext, useState } from "react";
import { ThemeContext } from "theme";

interface NumberOfPeopleFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const NumberOfPeopleFilter = ({
  setSearchFilters,
  searchFilters,
}: NumberOfPeopleFilterProps) => {
  const [count, setCount] = useState(0);
  const reset = () => setCount(0);
  const info = (
    <p>
      NUMBER OF PEOPLE REPRESENTS THE AMOUNT OF PEOPLE DETECTED WITHIN THE FRAME
      OF A SHOT
    </p>
  );
  const onPlusClick = () => setCount(count + 1);
  const onMinusClick = () => {
    if (count > 0) setCount(count - 1);
  };
  const { mainColor } = useContext(ThemeContext);
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      isComingSoon
      title="NUMBER OF PEOPLE"
    >
      <div className="flex self-center gap-7 w-[200px] justify-between">
        <button onClick={onMinusClick} disabled={count === 0}>
          <p className="text-[60px]">-</p>
        </button>
        <p className="text-[60px]">{count}</p>
        <button onClick={onPlusClick}>
          <p style={{ color: mainColor }} className="text-[60px]">
            +
          </p>
        </button>
      </div>
    </AdvancedFilterWrapper>
  );
};

export default NumberOfPeopleFilter;
