import { InterfaceSetup, Panels } from "App";
import { Shot } from "components/Content/Content";
import { useEffect, useState } from "react";

interface ThumbnailProps {
  shot: Shot;
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
  interfaceSetup: InterfaceSetup;
  random: number;
}

export const Thumbnail = ({
  shot,
  interfaceSetup,
  setInterfaceSetup,
  random,
}: ThumbnailProps) => {
  const [opacityCounter, setOpacityCounter] = useState(2);
  useEffect(() => {
    const opacityInterval = setInterval(() => {
      setOpacityCounter((opacityCounter) => opacityCounter + 1);
    }, random);
    return () => clearInterval(opacityInterval);
  });

  const calculatedFontSize = Math.max(10, interfaceSetup.thumbnailSize / 3);

  const thumbnailOverlay = (visible = "invisible") => (
    <div
      className={`absolute bottom-0 left-0 px-2 flex justify-between w-full pointer-events-none group-hover:visible ${visible}`}
    >
      <p
        className="whitespace-nowrap overflow-hidden overflow-ellipsis"
        style={{ fontSize: `${calculatedFontSize}px` }}
      >
        {shot.videoTitle}
      </p>
      <p
        className="whitespace-nowrap overflow-hidden overflow-ellipsis"
        style={{ fontSize: `${calculatedFontSize}px` }}
      >
        {shot.year}
      </p>
    </div>
  );
  const isSelected = interfaceSetup.selectedShot.shotId === shot.shotId;

  return (
    <div className=" flex-grow flex justify-center items-center ">
      <button
        className={`cursor-pointer relative group border border-transparent hover:border-white ${
          isSelected && "border border-white"
        }`}
        onClick={() => {
          setInterfaceSetup({
            ...interfaceSetup,
            isVideoPlayerOpen: true,
            selectedShot: shot,
            openPanel: interfaceSetup.openPanel || Panels.Info,
          });
        }}
      >
        <div
          className={`${
            isSelected ? "opacity-20" : "opacity-100"
          } hover:opacity-40`}
        >
          <img
            style={{
              maxWidth: `${100 + interfaceSetup.thumbnailSize * 3}px`,
            }}
            className="pointer-events-none transition-opacity duration-700 ease-in-out"
            src={shot.url1.replaceAll(" ", "%20").replace("cloud.google.com", "googleapis.com")}
            alt="img"
          />
          <img
            className="pointer-events-none absolute top-0 left-0 transition-opacity duration-700 ease-in-out"
            style={{ opacity: opacityCounter % 3 === 1 ? 0 : 1 }}
            src={shot.url2.replaceAll(" ", "%20").replace("cloud.google.com", "googleapis.com")}
            alt="img"
          />
          <img
            className="pointer-events-none absolute top-0 left-0 transition-opacity duration-700 ease-in-out"
            style={{ opacity: opacityCounter % 3 === 2 ? 1 : 0 }}
            src={shot.url3.replaceAll(" ", "%20").replace("cloud.google.com", "googleapis.com")}
            alt="img"
          />
        </div>
        {thumbnailOverlay(isSelected ? "visible" : "invisible")}
      </button>
    </div>
  );
};
