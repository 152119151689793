import { useState } from "react";

const TextField = ({ text }: { text: string }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const displayText = expanded ? text : text.slice(0, 100) + "..., [+]";

  return (
    <span onClick={handleClick} style={{ cursor: "pointer" }}>
      {displayText}
    </span>
  );
};

export default TextField;
