import { InfiniteQueryObserverSuccessResult } from "@tanstack/react-query";
import { InterfaceSetup } from "App";
import { SearchQuery, Shot } from "components/Content/Content";
import { StyledLoader } from "components/Content/Content.styled";
import { Thumbnail } from "components/Content/Results/Thumbnail/Thumbnail";
import InfiniteScroll from "react-infinite-scroll-component";

interface ResultsProps {
  shots: InfiniteQueryObserverSuccessResult<SearchQuery>;
  shotsData: Shot[];
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
  interfaceSetup: InterfaceSetup;
  isSpecificMovie?: boolean;
}

export const Results = ({
  shots,
  shotsData,
  setInterfaceSetup,
  interfaceSetup,
  isSpecificMovie,
}: ResultsProps) => {
  const movieCountText = `${shots.data.pages[0].countShots} shots ${
    !isSpecificMovie ? `in ${shots.data.pages[0].countMovies} movies` : ""
  }`;
  const isFetchMoreButtonVisible =
    shots.hasNextPage && !shots.isFetchingNextPage;
  return (
    <div
      id="scrollableDiv"
      className="flex flex-col p-4 w-full overflow-auto h-full"
    >
      <div className="flex justify-start items-center w-full">
        <p className="text-base my-0 mb-4">{movieCountText}</p>
      </div>
      <InfiniteScroll
        className="flex flex-wrap justify-start gap-[0.2em] w-full overflow-hidden"
        dataLength={shotsData.length}
        next={shots.fetchNextPage}
        hasMore={true}
        loader={<></>}
        scrollableTarget="scrollableDiv"
        endMessage={<></>}
      >
        {shotsData.map((shot, i) => (
          <Thumbnail
            key={i}
            shot={shot}
            setInterfaceSetup={setInterfaceSetup}
            interfaceSetup={interfaceSetup}
            random={Math.floor(Math.random() * 5000 + 2000)}
          />
        ))}
      </InfiniteScroll>
      <button
        className={`border border-white w-fit px-3 py-1 mt-4 opacity-50 self-end mr-[50px] ${
          !isFetchMoreButtonVisible && "hidden"
        }`}
        onClick={() => {
          shots.fetchNextPage();
        }}
      >
        {shots.isFetchingNextPage ? (
          <div className="relative flex justify-center items-center">
            <StyledLoader className="!absolute max-h-[10px] max-w-[10px] !m-0" />
            <p className="text-transparent text-base">Load more...</p>
          </div>
        ) : (
          <p className="text-base">Load more...</p>
        )}
      </button>
    </div>
  );
};
