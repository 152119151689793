const AutoPlay = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.98746 6.66448C9.93993 6.66448 9.89434 6.64493 9.86073 6.61013C9.82711 6.57534 9.80823 6.52814 9.80823 6.47893V4.56843C9.80823 3.22349 8.6684 3.12849 8.61998 3.12525H0.570102C0.522567 3.12525 0.476978 3.1057 0.443365 3.07091C0.409752 3.03611 0.390869 2.98891 0.390869 2.9397C0.390869 2.89049 0.409752 2.8433 0.443365 2.8085C0.476978 2.7737 0.522567 2.75415 0.570102 2.75415H8.6313C8.65747 2.75415 10.1667 2.86936 10.1667 4.56803V6.47852C10.1667 6.50293 10.1621 6.5271 10.1532 6.54966C10.1442 6.57222 10.131 6.59272 10.1143 6.60999C10.0977 6.62726 10.0779 6.64097 10.0561 6.65032C10.0344 6.65967 10.011 6.66448 9.98746 6.66448Z"
      fill="white"
    />
    <path
      d="M2.17106 5.10019C2.14417 5.10021 2.11765 5.09388 2.09361 5.08172C2.06957 5.06956 2.04868 5.05191 2.03261 5.03016C1.93317 4.91886 0.800028 3.79681 0.0529765 3.06164C0.0365368 3.04546 0.0234726 3.02611 0.0145528 3.00476C0.00563295 2.9834 0.0010376 2.96046 0.0010376 2.93728C0.0010376 2.91411 0.00563295 2.89117 0.0145528 2.86981C0.0234726 2.84845 0.0365368 2.82911 0.0529765 2.81293L2.05324 0.84971C2.06938 0.833434 2.08856 0.820555 2.10967 0.811821C2.13078 0.803087 2.1534 0.798672 2.17621 0.798832C2.19903 0.798993 2.22159 0.803726 2.24257 0.812756C2.26356 0.821787 2.28256 0.834935 2.29847 0.851437C2.31438 0.867938 2.32688 0.887465 2.33524 0.908882C2.34361 0.930299 2.34767 0.953179 2.3472 0.976194C2.34673 0.999209 2.34172 1.0219 2.33249 1.04295C2.32325 1.064 2.30995 1.08298 2.29338 1.0988L0.419936 2.93634C2.31177 4.80226 2.31702 4.81892 2.33353 4.87268C2.3419 4.89865 2.34409 4.92625 2.33992 4.95324C2.33574 4.98023 2.32532 5.00585 2.30949 5.02801C2.29366 5.05017 2.27288 5.06826 2.24883 5.0808C2.22478 5.09334 2.19813 5.09998 2.17106 5.10019Z"
      fill="white"
    />
    <path
      d="M1.53455 9.00945C1.50839 9.00945 0 8.89463 0 7.19538V5.28469C0 5.23548 0.0188828 5.18827 0.0524942 5.15347C0.0861057 5.11867 0.131692 5.09912 0.179226 5.09912C0.22676 5.09912 0.272347 5.11867 0.305958 5.15347C0.339569 5.18827 0.358452 5.23548 0.358452 5.28469V7.19538C0.358452 8.54087 1.49824 8.63548 1.54705 8.63912L9.5966 8.63669C9.64414 8.63669 9.68972 8.65624 9.72333 8.69105C9.75695 8.72585 9.77583 8.77305 9.77583 8.82226C9.77583 8.87148 9.75695 8.91868 9.72333 8.95348C9.68972 8.98828 9.64414 9.00783 9.5966 9.00783L1.53455 9.00945Z"
      fill="white"
    />
    <path
      d="M7.99307 10.9657C7.95878 10.9657 7.92527 10.9553 7.89683 10.936C7.86839 10.9167 7.84631 10.8893 7.8334 10.8573C7.8205 10.8252 7.81737 10.79 7.82441 10.7562C7.83145 10.7223 7.84833 10.6914 7.87291 10.6673L9.74773 8.8284C7.85526 6.9619 7.85001 6.94561 7.83273 6.89373C7.81966 6.85468 7.82077 6.81221 7.83586 6.77391C7.85096 6.73561 7.87907 6.70396 7.91516 6.68462C7.95126 6.66528 7.99299 6.65951 8.03292 6.66834C8.07284 6.67716 8.10836 6.70001 8.13312 6.73279C8.23113 6.84185 9.36623 7.96577 10.115 8.7042C10.1314 8.72039 10.1445 8.73974 10.1534 8.7611C10.1623 8.78246 10.1669 8.80541 10.1669 8.82859C10.1669 8.85178 10.1623 8.87473 10.1534 8.89609C10.1445 8.91745 10.1314 8.9368 10.115 8.95299L8.1136 10.9164C8.08136 10.9481 8.03808 10.9658 7.99307 10.9657Z"
      fill="white"
    />
  </svg>
);

export default AutoPlay;
