import { hsvaToHexa, rgbStringToHsva } from "@uiw/color-convert";
import { InterfaceSetup, SearchFilters, searchFiltersInitialState } from "App";
import AdvancedFilter from "components/Header/BottomLine/AdvancedFilter";
import BrightnessIcon from "components/Header/BottomLine/BrightnessIcon";
import SpeechBubleIcon from "components/Header/BottomLine/SpeechBuble";
import { CineticSlider } from "components/Header/CineticSlider";
import { GridIcon } from "icons/GridIcon";
import { ListIcon } from "icons/ListIcon";
import { PlayIcon } from "icons/PlayIcon";

interface BottomLineProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
  interfaceSetup: InterfaceSetup;
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
}

export const BottomLine = ({
  setSearchFilters,
  searchFilters,
  interfaceSetup,
  setInterfaceSetup,
}: BottomLineProps) => {
  const hexaColor = hsvaToHexa(
    rgbStringToHsva(
      `rgb(${searchFilters.advancedFilters.color.value.r}, ${searchFilters.advancedFilters.color.value.g}, ${searchFilters.advancedFilters.color.value.b})`
    )
  ).toUpperCase();

  const removeAllFilters = () =>
    setSearchFilters({
      ...searchFiltersInitialState,
      searchText: searchFilters.searchText,
    });
  const colorIcon = (
    <div
      className="flex w-4 h-4 rounded-full flex-shrink-0"
      style={{ backgroundColor: hexaColor }}
    />
  );
  const yearRangeIcon = (
    <span className="material-symbols-outlined text-white">date_range</span>
  );
  const yearRangeValue = `${searchFilters.advancedFilters.yearRange.value[0]} - ${searchFilters.advancedFilters.yearRange.value[1]}`;

  return (
    <div className="flex w-full justify-between">
      <div className="flex">
        <div className="flex justify-center items-center min-w-[324px] px-[2px] border-r border-white gap-2 text-white">
          <p>-</p>
          <CineticSlider
            value={interfaceSetup.thumbnailSize}
            onChange={(_, newValue) =>
              setInterfaceSetup({
                ...interfaceSetup,
                thumbnailSize: newValue as number,
              })
            }
            className="mt-[2.5px] !w-[230px]"
          />
          <p>+</p>
        </div>
        <div className="min-w-[45px] flex justify-center items-center border-r border-white">
          <PlayIcon />
        </div>
        <div className="min-w-[86px] flex justify-center items-center border-r border-white gap-2">
          <ListIcon />
          <GridIcon />
        </div>
        {searchFilters.advancedFilters.color.isEnabled && (
          <div className="w-[115px]">
            <AdvancedFilter
              filterName="COLOR"
              filterValue={hexaColor}
              icon={colorIcon}
            />
          </div>
        )}
        {searchFilters.advancedFilters.yearRange.isEnabled && (
          <AdvancedFilter
            filterName="MOVIE YEAR"
            filterValue={yearRangeValue}
            icon={yearRangeIcon}
          />
        )}
        {searchFilters.advancedFilters.brightness.isEnabled && (
          <AdvancedFilter
            filterName="BRIGHTNESS"
            filterValue={`${searchFilters.advancedFilters.brightness.value}%`}
            icon={<BrightnessIcon />}
          />
        )}
        {searchFilters.advancedFilters.dialog.isEnabled && (
          <AdvancedFilter
            className="w-[100px]"
            filterName="DIALOG"
            filterValue={searchFilters.advancedFilters.dialog.value}
            icon={<SpeechBubleIcon />}
          />
        )}
      </div>
      <button onClick={removeAllFilters}>
        <p className="text-gray-500 text-xs self-center p-2 hover:text-white">
          Clear all filters
        </p>
      </button>
    </div>
  );
};
