import { createContext, useEffect, useMemo, useState } from "react";

interface ThemeContextType {
  mainColor: string;
}

const initialContext: ThemeContextType = {
  mainColor: "#FFC700",
};

export const ThemeContext = createContext<ThemeContextType>(initialContext);

export const getClientNameFromURL = () => {
  const hostname = window.location.hostname;
  const clientName = hostname.split(".")[0];
  return clientName;
};

export const clientNameToColor = {
  mk2: "#EE0000",
  bologna: "#FFFFFF",
  studiocanal: "#CBA004",
};

function useClientTheme() {
  const [mainColor, setMainColor] = useState(initialContext.mainColor);

  useEffect(() => {
    const clientName = getClientNameFromURL();
    const color =
      clientNameToColor[clientName as keyof typeof clientNameToColor] ||
      initialContext.mainColor;
    setMainColor(color);
  }, []);

  return mainColor;
}

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const mainColor = useClientTheme();

  const themeValue = useMemo(() => ({ mainColor }), [mainColor]);

  return (
    <ThemeContext.Provider value={themeValue}>{children}</ThemeContext.Provider>
  );
};
