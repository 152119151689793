import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import BrightnessShade from "components/Content/AdvancedFilters/AdvancedFiltersFlat/BrightnessShade";
import { CineticSlider } from "components/Header/CineticSlider";

interface BrightnessFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const BrightnessFilter = ({
  setSearchFilters,
  searchFilters,
}: BrightnessFilterProps) => {
  const handleChange = (event: Event, newValue: number) => {
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        brightness: {
          isEnabled: true,
          value: newValue,
        },
      },
    });
  };
  const reset = () => {
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        brightness: { isEnabled: false, value: 0 },
      },
    });
  };
  const info = (
    <p>
      THE BRIGHTNESS OF A SHOT DEPENDS ON WHAT PHOTOGRAPHERS AND
      CINEMATOGRAPHERS CALL EXPOSURE. EXPOSURE DESCRIBES HOW MUCH LIGHT YOU LET
      INTO THE CAMERA TO GIVE YOU A CORRECTLY LIT SCENE.
    </p>
  );
  return (
    <AdvancedFilterWrapper reset={reset} info={info} title="BRIGHTNESS">
      <div>
        <CineticSlider
          onChange={(e, newValue) => handleChange(e, newValue as number)}
          className="w-full"
          valueLabelDisplay="on"
          value={searchFilters.advancedFilters.brightness.value}
        />
        <BrightnessShade />
      </div>
    </AdvancedFilterWrapper>
  );
};

export default BrightnessFilter;
