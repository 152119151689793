import styled from "styled-components";

interface RangeInputProps {
  value: number;
  max: number;
  mainColor: string;
}

export const StyledInput = styled.input<RangeInputProps>`
  width: 100%;
  height: 2px;
  appearance: none;
  -webkit-appearance: none;
  background: linear-gradient(
    to right,
    ${(props) => props.mainColor}
      ${(props) => `calc((100% * ${props.value}) / ${props.max})`},
    #818181 ${(props) => `calc((100% * ${props.value}) / ${props.max})`},
    #818181
  );

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5px;
    height: 10px;
    background: ${(props) => props.mainColor};
    cursor: pointer;
  }
`;

export const StyledVolumeInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border-radius: 5px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.5px;
    height: 10px;
    background: transparent;
    cursor: pointer;
  }
`;
