import { InterfaceSetup, Panels, SearchFilters } from "App";
import { CineticSwitch } from "components/Header/CineticSwitch";
import ImageUploadButton from "components/Header/MiddleLine/ImageUploadButton";
import { CameraIcon } from "icons/CameraIcon";
import { MicroIcon } from "icons/MicroIcon";
import { SearchIcon } from "icons/SearchIcon";
import { useContext } from "react";
import { ThemeContext } from "theme";

interface MiddleLineProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
  interfaceSetup: InterfaceSetup;
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
}

export const MiddleLine = ({
  setSearchFilters,
  searchFilters,
  interfaceSetup,
  setInterfaceSetup,
}: MiddleLineProps) => {
  const onSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilters({
      ...searchFilters,
      searchText: e.target.value,
      searchFile: null,
      searchFileName: "",
    });
    setInterfaceSetup({ ...interfaceSetup, isVideoPlayerOpen: false });
  };

  const onSwitchClick = () => {
    setInterfaceSetup({
      ...interfaceSetup,
      openPanel:
        interfaceSetup.openPanel === Panels.AdvancedFilters
          ? null
          : Panels.AdvancedFilters,
    });
  };

  const { mainColor } = useContext(ThemeContext);
  return (
    <>
      <div className="min-w-[306px] flex  items-center border-r border-white gap-2 pl-5">
        <SearchIcon />
        <input
          className="bg-black w-[240px] text-white text-base border-transparent outline-none"
          placeholder="Search"
          onChange={onSearchTextChange}
          value={searchFilters.searchText}
        />
      </div>
      <div className="min-w-[45px] flex justify-center items-center border-r border-white">
        <MicroIcon />
      </div>
      <ImageUploadButton
        searchFilters={searchFilters}
        setSearchFilters={setSearchFilters}
      />
      <div className="min-w-[45px] flex justify-center items-center border-r border-white">
        <CameraIcon />
      </div>
      <div className="flex w-full justify-end items-center text-gray-600 text-xs pr-[20px]">
        <p>Advanced Filters</p>
        <button
          className="relative flex items-center cursor-pointer"
          onClick={onSwitchClick}
        >
          <CineticSwitch
            className="flex justify-center items-center"
            checked={interfaceSetup.openPanel === Panels.AdvancedFilters}
            mainColor={mainColor}
          />
          <p
            className={`absolute top-[8px] left-[14px] font-bold text-[10px] ${
              interfaceSetup.openPanel === Panels.AdvancedFilters
                ? "text-black"
                : ""
            }`}
          >
            {interfaceSetup.openPanel === Panels.AdvancedFilters ? "ON" : "OFF"}
          </p>
        </button>
      </div>
    </>
  );
};
