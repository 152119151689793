export const ListIcon = () => (
  <svg
    width="23"
    height="14"
    viewBox="0 0 23 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5.20898" width="16.9286" height="2.6044" fill="#707070" />
    <rect width="2.6044" height="2.6044" fill="#707070" />
    <rect y="5.20879" width="2.6044" height="2.6044" fill="#707070" />
    <rect y="10.4176" width="2.6044" height="2.6044" fill="#707070" />
    <rect
      x="5.20898"
      y="5.20879"
      width="16.9286"
      height="2.6044"
      fill="#707070"
    />
    <rect
      x="5.20898"
      y="10.4176"
      width="16.9286"
      height="2.6044"
      fill="#707070"
    />
  </svg>
);
