import { InterfaceSetup, SearchFilters } from "App";
import { BottomLine } from "components/Header/BottomLine/BottomLine";
import { MiddleLine } from "components/Header/MiddleLine/MiddleLine";
import { TopLine } from "components/Header/TopLine/TopLine";

interface HeaderProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
  interfaceSetup: InterfaceSetup;
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
}

export const Header = ({
  setSearchFilters,
  searchFilters,
  interfaceSetup,
  setInterfaceSetup,
}: HeaderProps) => (
  <header className="flex flex-col top-0 text-center z-10 w-full items-end">
    <TopLine />
    <div className="flex h-[45px] border-b border-white w-full bg-black">
      <MiddleLine
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
        interfaceSetup={interfaceSetup}
        setInterfaceSetup={setInterfaceSetup}
      />
    </div>

    <div className="flex h-[45px] border-b border-white w-full bg-black">
      <BottomLine
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
        interfaceSetup={interfaceSetup}
        setInterfaceSetup={setInterfaceSetup}
      />
    </div>
  </header>
);
