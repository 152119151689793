import { SearchFilters } from "App";
import AspectRatioFilter from "components/Content/AdvancedFilters/AspectRatioFilter";
import BrightnessFilter from "components/Content/AdvancedFilters/BrightnessFilter";
import ColorFilter from "components/Content/AdvancedFilters/ColorFilter";
import CompositionFilter from "components/Content/AdvancedFilters/CompositionFilter";
import DialogFilter from "components/Content/AdvancedFilters/DialogFilter";
import DurationFilter from "components/Content/AdvancedFilters/DurationFilter";
import LocationFilter from "components/Content/AdvancedFilters/LocationFilter";
import NumberOfPeopleFilter from "components/Content/AdvancedFilters/NumberOfPeopleFilter";
import ShotTypeFilter from "components/Content/AdvancedFilters/ShotTypeFilter";
import TemperatureFilter from "components/Content/AdvancedFilters/TemperatureFilter";
import TimeOfDayFilter from "components/Content/AdvancedFilters/TimeOfDayFilter";
import YearFilter from "components/Content/AdvancedFilters/YearFilter";
import { useLocation } from "react-router-dom";

interface AdvancedFiltersProps {
  isOpen: boolean;
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

export const AdvancedFilters = ({
  isOpen,
  setSearchFilters,
  searchFilters,
}: AdvancedFiltersProps) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isSpecificMovieView = currentPath.match(/^\/movie\/(\d+)$/);
  return (
    <div
      className={`width-min-max-width duration-300 ease-in-out flex flex-col ${
        isOpen ? "min-w-[401px] max-w-[401px] w-[401px]" : "w-0 min-w-0 max-w-0"
      } justify-start items-start bg-black h-full overflow-y-auto overflow-x-hidden border-l`}
    >
      {!isSpecificMovieView && (
        <YearFilter
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
        />
      )}
      <ColorFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      <BrightnessFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      <DialogFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      <NumberOfPeopleFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      <TemperatureFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      <ShotTypeFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      <TimeOfDayFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      {!isSpecificMovieView && (
        <AspectRatioFilter
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
        />
      )}
      <DurationFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
      {false && (
        <LocationFilter
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
        />
      )}
      <CompositionFilter
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
      />
    </div>
  );
};
