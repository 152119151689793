import { useInfiniteQuery } from "@tanstack/react-query";
import { SearchFilters } from "App";
import api from "api";
import { SearchQuery } from "components/Content/Content";

const addAdvancedFilters = (
  formData: FormData,
  { advancedFilters: { color, yearRange, dialog, brightness } }: SearchFilters
) => {
  if (color.isEnabled) {
    const rgbString = `${color.value.r},${color.value.g},${color.value.b}`;
    formData.append("color", rgbString);
  }

  if (yearRange.isEnabled) {
    formData.append("yearRange", yearRange.value.join(","));
  }

  if (dialog.isEnabled) {
    formData.append("subtitles", dialog.value);
  }

  if (brightness.isEnabled) {
    formData.append("brightness", (brightness.value / 100).toString());
  }
};

export const useSearch = (searchFilters: SearchFilters, pageSize = 50) => {
  let inputType = "text";

  return useInfiniteQuery({
    queryKey: ["search", searchFilters],
    queryFn: ({ pageParam = 1 }): Promise<SearchQuery> => {
      const formData = new FormData();
      formData.append("page", pageParam.toString());
      formData.append("pageSize", pageSize.toString());

      addAdvancedFilters(formData, searchFilters);

      if (searchFilters.searchFile) {
        inputType = "image";
        formData.append("searchFile", searchFilters.searchFile);
      } else formData.append("searchText", searchFilters.searchText);

      formData.append("inputType", inputType);
      formData.append("videoId", searchFilters.movieId);

      return api.post("/search", formData).then((res) => res.data);
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    refetchOnWindowFocus: false,
  });
};
