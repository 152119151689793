import { Tooltip } from "@mui/material";
import { SearchFilters } from "App";
import { CamcoderIcon } from "icons/CamcoderIcon";
import React, { useRef } from "react";

interface ImageUploadButtonProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const ImageUploadButton = ({
  searchFilters,
  setSearchFilters,
}: ImageUploadButtonProps) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setSearchFilters({
        ...searchFilters,
        searchText: "",
        searchFile: file,
        searchFileName: file.name,
      });
    }
  };

  const handleButtonClick = () => {
    fileInput.current?.click();
  };

  return (
    <Tooltip title="Search by image">
      <div className="hover:bg-slate-600 min-w-[45px] border-r border-white">
        <button
          className="w-full h-full flex justify-center items-center"
          onClick={handleButtonClick}
        >
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInput}
            onChange={handleFileChange}
            accept="image/*"
          />
          <CamcoderIcon />
        </button>
      </div>
    </Tooltip>
  );
};

export default ImageUploadButton;
