import { InterfaceSetup, Panels, SearchFilters } from "App";
import { AdvancedFilters } from "components/Content/AdvancedFilters/AdvancedFilters";
import { Header } from "components/Header/Header";

interface PageWrapperProps {
  children: React.ReactNode;
  searchFilters: SearchFilters;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
  interfaceSetup: InterfaceSetup;
  setInterfaceSetup: React.Dispatch<React.SetStateAction<InterfaceSetup>>;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  searchFilters,
  setSearchFilters,
  interfaceSetup,
  setInterfaceSetup,
}) => {
  return (
    <>
      <Header
        setSearchFilters={setSearchFilters}
        searchFilters={searchFilters}
        interfaceSetup={interfaceSetup}
        setInterfaceSetup={setInterfaceSetup}
      />
      <div className="flex w-full h-[calc(100%-152px)]">
        {children}
        <AdvancedFilters
          isOpen={interfaceSetup.openPanel === Panels.AdvancedFilters}
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
        />
      </div>
    </>
  );
};

export default PageWrapper;
