export const CameraIcon = () => {
  return (
    <svg
      width="18"
      height="9"
      viewBox="0 0 18 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6411 0H0V8.49683H12.6411V0ZM4.12378 3.08975H2.1197V2.39456H4.12378V3.08975Z"
        fill="#707070"
      />
      <path
        d="M13.334 5.49082L17.0339 8.18006V0.31665L13.334 3.0055V5.49082Z"
        fill="#707070"
      />
    </svg>
  );
};
