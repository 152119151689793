const BrightnessIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0246 17.6543C14.8497 17.6543 17.1544 15.3497 17.1544 12.5245C17.1544 9.69935 14.8498 7.41943 12.0246 7.41943C9.19947 7.41943 6.91956 9.69935 6.91956 12.5245C6.91956 15.3496 9.19947 17.6543 12.0246 17.6543ZM12.0246 7.98941C14.5275 7.98941 16.5844 10.0463 16.5844 12.5492C16.5844 15.0521 14.5275 17.0843 12.0246 17.0843V7.98941Z"
      fill="white"
    />
    <path d="M11.7522 20.2316H12.3221V24.0728H11.7522V20.2316Z" fill="white" />
    <path
      d="M11.7522 0.926758H12.3221V4.76796H11.7522V0.926758Z"
      fill="white"
    />
    <path d="M0.42688 12.252H4.26808V12.8219H0.42688V12.252Z" fill="white" />
    <path d="M19.7317 12.252H23.5729V12.8219H19.7317V12.252Z" fill="white" />
    <path
      d="M3.60596 20.4901L6.32198 17.774L6.7251 18.1772L4.00907 20.8932L3.60596 20.4901Z"
      fill="white"
    />
    <path
      d="M17.299 6.85433L20.015 4.13831L20.4181 4.54142L17.7021 7.25745L17.299 6.85433Z"
      fill="white"
    />
    <path
      d="M3.63489 4.51286L4.03801 4.10974L6.75403 6.82577L6.35091 7.22889L3.63489 4.51286Z"
      fill="white"
    />
    <path
      d="M17.2877 18.188L17.6908 17.7849L20.4069 20.5009L20.0037 20.9041L17.2877 18.188Z"
      fill="white"
    />
  </svg>
);

export default BrightnessIcon;
