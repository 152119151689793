import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import AspectRatioFilterFlat from "components/Content/AdvancedFilters/AdvancedFiltersFlat/AspectRatioFilterFlat";
import { useContext } from "react";
import { ThemeContext } from "theme";

interface AspectRatioFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const AspectRatioFilter = ({
  setSearchFilters,
  searchFilters,
}: AspectRatioFilterProps) => {
  const reset = () => () => {};
  const info = (
    <p>
      AN ASPECT RATIO DESCRIBES THE WIDTH AND HEIGHT OF A SCREEN OR IMAGE. AN
      ASPECT RATIO CONSISTS OF TWO NUMBERS SEPARATED BY A COLON, THE FIRST
      NUMBER DENOTING THE IMAGE’S WIDTH AND THE SECOND ITS HEIGHT.
    </p>
  );
  const { mainColor } = useContext(ThemeContext);
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      title="ASPECT RATIO"
      isComingSoon
    >
      <AspectRatioFilterFlat accentColor={mainColor} />
    </AdvancedFilterWrapper>
  );
};

export default AspectRatioFilter;
