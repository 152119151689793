import { useAuth } from "authentication/AuthContext";
import { loginUser, type Credentials } from "authentication/authService";
import Input from "components/Login/Input";
import LogoRefractio from "icons/LogoRefractio";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "theme";
import { getClientNameFromSubdomain } from "utils";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [subdomain, setSubdomain] = useState(String);
  const [hasClient, setHasClient] = useState(false);
  const [clientLogo, setClientLogo] = useState(null);

  useEffect(() => {
    const loadClientLogo = async () => {
      try {
        const logoModule = await import(
          `icons/${subdomain}_logo.png`
          );
          setClientLogo(logoModule.default);
        } catch (error) {
          console.error("Failed to load client logo:", error);
        }
      }
        const subdomain = getClientNameFromSubdomain();
        setSubdomain(subdomain);
        if (subdomain != '' && subdomain != 'localhost' && subdomain != 'staging' && subdomain != 'app') {
        setHasClient(true);
        loadClientLogo();
      }
  }, []);

  const { login } = useAuth();

  const navigate = useNavigate();

  const handleLogin = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    try {
      const credentials: Credentials = { email, password };
      await loginUser(credentials);
      login();
      navigate("/");
    } catch (error) {
      setErrorMessage("You have entered an invalid username or password");
    }
  };

  const { mainColor } = useContext(ThemeContext);

  return (
    <div className="flex flex-col h-full justify-between items-center">
      <div className="flex flex-col h-[320px] justify-between items-center m-auto w-full">
        <div className="flex justify-center items-center w-full gap-7">
          <LogoRefractio className="w-[265px]" />
          { hasClient && <p className="text-[70px]">×</p>}
          { hasClient && <img src={clientLogo!} alt="Logo" className="h-[46px]" />}
        </div>
        <p className="text-white text-xl mt-10 mb-7">USER LOGIN</p>
        {errorMessage && <p className="text-red-600 mb-3">{errorMessage}</p>}
        <form onSubmit={handleLogin}>
          <div className="flex flex-col justify-center items-center gap-3">
            <Input value={email} onChange={setEmail} type="email" />
            <Input value={password} onChange={setPassword} type="password" />
            <button
              style={{ backgroundColor: mainColor }}
              className="relative flex justify-center items-center h-[38px] w-[190px] disabled:bg-[#84712a]"
              type="submit"
              disabled={!email || !password}
            >
              <span className="material-symbols-outlined absolute left-[45px] top-2">
                login
              </span>
              Login
            </button>
          </div>
        </form>
      </div>
      <p className="text-[#8E8E8E] text-xl">
        ©2024 ALL RIGHTS RESERVED BY REFRACTIO™
      </p>
    </div>
  );
};

export default Login;
