import EmptyRectangleIcon from "icons/EmptyRectangleIcon";
import TimelineCusorIcon from "icons/TimelineCusorIcon";

interface TimelineProps {
  percentage: number;
}

const Timeline = ({ percentage }: TimelineProps) => {
  return (
    <div className="relative w-full flex">
      <div className="pt-[7px]">
        <EmptyRectangleIcon />
      </div>
      <div
        style={{
          position: "absolute",
          left: `${Math.round(340 * percentage)}px`,
        }}
      >
        <TimelineCusorIcon />
      </div>
    </div>
  );
};

export default Timeline;
