import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import HumanSilhouetteIcon from "icons/HumanSilhouetteIcon";
import { useContext, useState } from "react";
import { ThemeContext } from "theme";

enum ShotTypes {
  VeryLarge = "Very Large",
  Large = "Large",
  American = "American",
  LessAmerican = "Less American",
  AlmostCloseUp = "Almost Close Up",
  CloseUp = "Close Up",
  ExtremeCloseUp = "Extreme Close Up",
}

interface ShotTypeFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const ShotTypeFilter = ({
  setSearchFilters,
  searchFilters,
}: ShotTypeFilterProps) => {
  const [selectedShotType, setSelectedShotType] = useState<ShotTypes>(
    ShotTypes.Large
  );
  const reset = () => setSelectedShotType(ShotTypes.Large);
  const info = (
    <p>
      SHOT TYPE IS HOW MUCH OF THE SETTING OR SUBJECT IS DISPLAYED WITHIN A
      GIVEN FRAME OF A VIDEO, PHOTO, OR ANIMATION, HENCE THE SCOPE OR SIZE OF
      THE SHOT.
    </p>
  );
  const { mainColor } = useContext(ThemeContext);
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      isComingSoon
      title="TYPE OF SHOT"
    >
      <div className="flex flex-col gap-4">
        <div className="relative flex justify-center self-center w-[311px] h-[173px]">
          <div className="absolute top-3">
            <HumanSilhouetteIcon />
          </div>
          <button
            style={{
              borderColor:
                selectedShotType === ShotTypes.VeryLarge ? mainColor : "#fff",
            }}
            className={`absolute w-full h-full border`}
            onClick={() => setSelectedShotType(ShotTypes.VeryLarge)}
          />
          <button
            style={{
              borderTopColor: [ShotTypes.VeryLarge, ShotTypes.Large].includes(
                selectedShotType
              )
                ? mainColor
                : "#fff",
              borderRightColor:
                selectedShotType === ShotTypes.Large ? mainColor : "#fff",
              borderBottomColor:
                selectedShotType === ShotTypes.Large ? mainColor : "#fff",
              borderLeftColor:
                selectedShotType === ShotTypes.Large ? mainColor : "#fff",
            }}
            className={`absolute w-[233px] h-[130px] border`}
            onClick={() => setSelectedShotType(ShotTypes.Large)}
          />
          <button
            style={{
              borderTopColor: [
                ShotTypes.VeryLarge,
                ShotTypes.Large,
                ShotTypes.American,
              ].includes(selectedShotType)
                ? mainColor
                : "#fff",
              borderRightColor:
                selectedShotType === ShotTypes.American ? mainColor : "#fff",
              borderBottomColor:
                selectedShotType === ShotTypes.American ? mainColor : "#fff",
              borderLeftColor:
                selectedShotType === ShotTypes.American ? mainColor : "#fff",
            }}
            className={`absolute w-[163px] h-[90px] border `}
            onClick={() => setSelectedShotType(ShotTypes.American)}
          />
          <button
            style={{
              borderTopColor: [
                ShotTypes.VeryLarge,
                ShotTypes.Large,
                ShotTypes.American,
                ShotTypes.LessAmerican,
              ].includes(selectedShotType)
                ? mainColor
                : "#fff",
              borderRightColor:
                selectedShotType === ShotTypes.LessAmerican
                  ? mainColor
                  : "#fff",
              borderBottomColor:
                selectedShotType === ShotTypes.LessAmerican
                  ? mainColor
                  : "#fff",
              borderLeftColor:
                selectedShotType === ShotTypes.LessAmerican
                  ? mainColor
                  : "#fff",
            }}
            className={`absolute w-[127px] h-[71px] border`}
            onClick={() => setSelectedShotType(ShotTypes.LessAmerican)}
          />
          <div className="relative top-2 flex justify-center">
            <button
              style={{
                borderColor:
                  selectedShotType === ShotTypes.AlmostCloseUp
                    ? mainColor
                    : "#fff",
              }}
              className={`absolute w-[95px] h-[53px] border`}
              onClick={() => setSelectedShotType(ShotTypes.AlmostCloseUp)}
            />
            <button
              style={{
                borderTopColor: [
                  ShotTypes.AlmostCloseUp,
                  ShotTypes.CloseUp,
                ].includes(selectedShotType)
                  ? mainColor
                  : "#fff",
                borderRightColor:
                  selectedShotType === ShotTypes.CloseUp ? mainColor : "#fff",
                borderBottomColor:
                  selectedShotType === ShotTypes.CloseUp ? mainColor : "#fff",
                borderLeftColor:
                  selectedShotType === ShotTypes.CloseUp ? mainColor : "#fff",
              }}
              className={`absolute w-[57px] h-[32px] border`}
              onClick={() => setSelectedShotType(ShotTypes.CloseUp)}
            />
          </div>
          <button
            style={{
              borderColor:
                selectedShotType === ShotTypes.ExtremeCloseUp
                  ? mainColor
                  : "#fff",
            }}
            className={`absolute w-[30px] h-[17px] top-4 border`}
            onClick={() => setSelectedShotType(ShotTypes.ExtremeCloseUp)}
          />
        </div>
        <p>{selectedShotType.toUpperCase()}</p>
      </div>
    </AdvancedFilterWrapper>
  );
};

export default ShotTypeFilter;
