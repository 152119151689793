import Slider, { SliderProps } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

export const CineticSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  color: "#ffffff",
  "& .MuiSlider-thumb": {
    borderRadius: 0,
    width: 6,
    height: 15,
  },
  "& .MuiSlider-rail": {
    height: 1,
  },
  "& .MuiSlider-track": {
    height: 0,
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -4,
    backgroundColor: "unset",
    "&:before": {
      content: '""', // Add content for ::before pseudo-element
      position: "absolute",
      top: 23, // Adjust as needed to position the triangle
      left: "50%", // Position the triangle horizontally in the middle
      width: 0,
      height: 0,
      borderLeft: "4px solid transparent", // Size of the triangle
      borderRight: "4px solid transparent", // Size of the triangle
      borderBottom: "4px solid #ffffff", // Color of the triangle
      transform: "translateX(-50%) rotate(180deg)", // Center the triangle horizontally
    },
    "& *": {
      background: "transparent",
      color: "#fff",
    },
  },
}));
