interface CompositionFilterFlatProps {
  accentColor: string;
}

const CompositionFilterFlat = ({accentColor}: CompositionFilterFlatProps) => (
  <svg
    width="332"
    height="222"
    viewBox="0 0 332 222"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="1.47656"
      width="90"
      height="55"
      fill="black"
      stroke="white"
    />
    <rect
      x="0.5"
      y="88.4766"
      width="90"
      height="55"
      fill="black"
      stroke="white"
    />
    <rect
      x="0.5"
      y="165.477"
      width="90"
      height="55"
      fill="black"
      stroke="white"
    />
    <rect
      x="121.5"
      y="1.47656"
      width="90"
      height="55"
      fill="black"
      stroke="white"
    />
    <rect
      x="121.5"
      y="88.4766"
      width="90"
      height="55"
      fill="black"
      stroke="white"
    />
    <rect
      x="121.5"
      y="165.477"
      width="90"
      height="55"
      fill="black"
      stroke="white"
    />
    <rect
      x="240.5"
      y="1.47656"
      width="91"
      height="55"
      fill="black"
      stroke={accentColor}
    />
    <rect x="240.5" y="88.4766" width="91" height="55" stroke="white" />
    <rect x="240.5" y="165.477" width="91" height="55" stroke="white" />
    <line x1="45.5" y1="0.976563" x2="45.5" y2="56.9766" stroke="white" />
    <line x1="54.5" y1="164.977" x2="54.5" y2="220.977" stroke="white" />
    <line x1="285.5" y1="98.9766" x2="285.5" y2="132.977" stroke="white" />
    <line x1="269" y1="116.477" x2="303" y2="116.477" stroke="white" />
    <line x1="269" y1="200.477" x2="303" y2="200.477" stroke="white" />
    <line x1="273.448" y1="102.716" x2="296.368" y2="127.636" stroke="white" />
    <line x1="299.276" y1="102.046" x2="273.356" y2="128.326" stroke="white" />
    <line x1="269.5" y1="178.977" x2="269.5" y2="200.977" stroke="white" />
    <line x1="121" y1="38.4766" x2="212" y2="38.4766" stroke="white" />
    <line x1="129.422" y1="88.7081" x2="164.422" y2="143.708" stroke="white" />
    <line x1="120.772" y1="220.531" x2="165.772" y2="197.531" stroke="white" />
    <line x1="201.417" y1="88.2522" x2="164.417" y2="144.252" stroke="white" />
    <line x1="207.752" y1="221.411" x2="165.752" y2="197.411" stroke="white" />
    <line x1="330.259" y1="1.40403" x2="241.259" y2="55.404" stroke={accentColor} />
    <line x1="275.39" y1="1.66421" x2="319.39" y2="56.6642" stroke={accentColor} />
    <line x1="121" y1="17.4766" x2="212" y2="17.4766" stroke="white" />
    <circle cx="45" cy="115.977" r="14.5" stroke="white" />
    <rect x="68.5" y="165.477" width="22" height="22" stroke="white" />
    <rect x="54.5" y="165.477" width="14" height="14" stroke="white" />
    <rect x="60.5" y="179.477" width="8" height="8" stroke="white" />
    <rect x="54.5" y="179.477" width="3" height="3" stroke="white" />
    <rect x="57.5" y="179.477" width="3" height="3" stroke="white" />
    <rect x="54.5" y="182.477" width="5" height="5" stroke="white" />
  </svg>
);

export default CompositionFilterFlat;
