import { ColorResult, color, rgbaToHsva } from "@uiw/color-convert";
import Wheel from "@uiw/react-color-wheel";
import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";

interface ColorFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const ColorFilter = ({ setSearchFilters, searchFilters }: ColorFilterProps) => {
  const { hsva, hsla, hex, rgba } = color(
    rgbaToHsva({ ...searchFilters.advancedFilters.color.value, a: 1 })
  );

  const onChange = (color: ColorResult) =>
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        color: {
          isEnabled: true,
          value: color.rgb,
        },
      },
    });

  const reset = () => {
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        color: {
          isEnabled: false,
          value: { r: 255, g: 255, b: 255 },
        },
      },
    });
  };
  const info = (
    <p>
      A COLOR IS DEFINED BY:
      <br />
      HUE – THE COLOR ITSELF
      <br />
      SATURATION – INTENSITY OF THE COLOR
      <br />
      BRIGHTNESS – THE DARKNESS OR LIGHTNESS OF A COLOR.
      <br />
      THIS FILTER ALLOW YOU TO SEARCH A SHOT WITH ITS PROMINENT COLOR.
    </p>
  );
  return (
    <AdvancedFilterWrapper reset={reset} info={info} title="COLOR">
      <div className="flex flex-col gap-7 h-full w-full">
        <>
          <Wheel
            className="cursor-pointer self-center"
            color={hsva}
            onChange={onChange}
          />
          <div className="flex items-center justify-between">
            <div
              className="h-[40px] w-[40px]"
              // have to use style for bg because it won't work with tailwind
              style={{ background: hex }}
            />
            <p className="w-[50px]">{hex.toUpperCase()}</p>
            <div className="flex gap-10">
              <div className="flex flex-col justify-start w-[50px] gap-1">
                <p className="text-left">R: {rgba.r}</p>
                <p className="text-left">G: {rgba.g}</p>
                <p className="text-left">B: {rgba.b}</p>
              </div>
              <div className="flex flex-col justify-start w-[50px] gap-1">
                <p className="text-left">H: {Math.trunc(hsla.h)}°</p>
                <p className="text-left">S: {Math.trunc(hsla.s)}%</p>
                <p className="text-left">L: {Math.trunc(hsla.l)}%</p>
              </div>
            </div>
          </div>
        </>
      </div>
    </AdvancedFilterWrapper>
  );
};

export default ColorFilter;
