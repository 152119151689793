interface InputProps {
  value: string;
  onChange: (newValue: any) => void;
  type: string;
}

const Input = ({ value, onChange, type }: InputProps) => (
  <div className="flex bg-black border w-[330px] h-[38px]">
    <span className="flex  justify-center items-center material-symbols-outlined text-white w-[40px] border-r">
      {type === "email" ? "person" : "lock"}
    </span>
    <input
      className=" text-white text-lg px-4 w-full bg-transparent focus-visible:outline-0"
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={type === "email" ? "Email" : "Password"}
    />
  </div>
);

export default Input;
