export const GridIcon = () => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="5.36199" height="6.43439" fill="white" />
    <rect x="6.43555" width="5.36199" height="6.43439" fill="white" />
    <rect x="12.8691" width="5.36199" height="6.43439" fill="white" />
    <rect
      x="12.8691"
      y="7.50714"
      width="5.36199"
      height="6.43439"
      fill="white"
    />
    <rect
      x="6.43555"
      y="7.50714"
      width="5.36199"
      height="6.43439"
      fill="white"
    />
    <rect y="7.50714" width="5.36199" height="6.43439" fill="white" />
  </svg>
);
