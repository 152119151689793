import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import TemperatureFilterFlat from "components/Content/AdvancedFilters/AdvancedFiltersFlat/TemperatureFilterFlat";

interface BrightnessFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const BrightnessFilter = ({
  setSearchFilters,
  searchFilters,
}: BrightnessFilterProps) => {
  const reset = () => () => {};
  const info = (
    <p>
      COLOR TEMPERATURE IS A SYSTEM THAT USES NUMERICAL VALUES TO MEASURE THE
      COLOR CHARACTERISTICS OF A LIGHT SOURCE ON A SPECTRUM RANGING FROM WARM
      COLORS TO COOL COLORS. THE NUMERICAL VALUES ARE REFERRED TO AS DEGREES
      KELVIN (K).
    </p>
  );
  return (
    <AdvancedFilterWrapper
      reset={reset}
      info={info}
      title="TEMPERATURE"
      isComingSoon
    >
      <TemperatureFilterFlat />
    </AdvancedFilterWrapper>
  );
};

export default BrightnessFilter;
