const BrightnessShade = () => (
  <svg
    width="339"
    height="45"
    viewBox="0 0 339 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="339"
      y="0.5"
      width="44"
      height="339"
      transform="rotate(90 339 0.5)"
      fill="url(#paint0_linear_514_2335)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_514_2335"
        x1="361"
        y1="0.5"
        x2="361"
        y2="339.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default BrightnessShade;
