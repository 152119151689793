import { Children, useContext, useState } from "react";
import { ThemeContext } from "theme";

interface AdvancedFilterWrapperProps {
  reset: () => void;
  info: React.ReactNode;
  children: React.ReactNode;
  isComingSoon?: boolean;
  title: string;
}

const AdvancedFilterWrapper = ({
  reset,
  info,
  children,
  isComingSoon,
  title,
}: AdvancedFilterWrapperProps) => {
  const [isInfoDisplayed, setIsInfoDisplayed] = useState(false);
  const { mainColor } = useContext(ThemeContext);
  return (
    <div className="pt-[13px] px-[30px] w-full h-full flex flex-col border-b-[1px]">
      <div className="text-white flex gap-4">
        <div className="flex grow">
          <p className="border-white border-b-[1px]">{title}</p>
        </div>
        {isComingSoon ? (
          <p style={{ color: mainColor }}>(COMING SOON)</p>
        ) : (
          <button onClick={reset}>
            <p className="text-xs">RESET</p>
          </button>
        )}
        <button
          onClick={() => setIsInfoDisplayed(!isInfoDisplayed)}
          className="text-white"
        >
          <p className="text-xs">INFO</p>
        </button>
      </div>
      <div className="flex w-full py-7">
        <div className="relative flex h-full w-full justify-center items-center">
          {isInfoDisplayed && (
            <div className="absolute flex w-full h-full bg-black z-[1000] items-center">
              <p>{info}</p>
            </div>
          )}
          {Children.only(children)}
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilterWrapper;
