import { InfiniteQueryObserverSuccessResult } from "@tanstack/react-query";
import {
  InterfaceSetup,
  SearchFilters,
  interfaceSetupInitialState,
  searchFiltersInitialState,
} from "App";
import api from "api";
import { SearchQuery, Shot } from "components/Content/Content";
import { StyledLoader } from "components/Content/Content.styled";
import { Results } from "components/Content/Results/Results";
import { VideoPlayer } from "components/Content/Results/VideoPlayer/VideoPlayer";
import MovieInfo from "components/SpecificMovie/MovieInfo";
import MovieTimeline from "components/SpecificMovie/MovieTimeline";
import { useSearch } from "queries/useSearch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export interface Metrics {
  numberOfScenes: number;
  numberOfObjects: any;
  numberOfCloseUps: any;
  numberOfDialogues: number;
}

export interface MovieData {
  id: number;
  title: string;
  videoDuration: number;
  year: number;
  image: string;
  synopsis: string;
  genres: string[];
  director: string[];
  cinematographer: string[];
  productionManager: string[];
  costumeDesigner: string[];
  cast: any;
  country: string[];
  metrics: Metrics;
}

interface SpecificMovieViewProps {
  interfaceSetup: InterfaceSetup;
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
  searchFilters: SearchFilters;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilters>>;
}

const SpecificMovieView = ({
  searchFilters,
  setSearchFilters,
  interfaceSetup,
  setInterfaceSetup,
}: SpecificMovieViewProps) => {
  const [movieData, setMovieData] = useState<MovieData | null>(null);
  const [debouncedSearchFilters, setDebouncedSearchFilters] =
    useState<SearchFilters>(searchFilters);

  const { videoId } = useParams<{ videoId: string }>();
  useEffect(() => {
    setInterfaceSetup({ ...interfaceSetupInitialState, thumbnailSize: 1 });
    setSearchFilters({ ...searchFiltersInitialState, movieId: videoId! });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchFilters(searchFilters);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchFilters]);

  const shots = useSearch(debouncedSearchFilters, 2000);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get<MovieData>("/movie", {
          params: { video_id: videoId },
        });
        setMovieData(response.data);
      } catch (error) {
        console.error("Failed to fetch movie data:", error);
      }
    };
    fetchData();
  }, [videoId]);

  if (!movieData)
    return (
      <div className="w-full">
        <StyledLoader />
      </div>
    );

  if (shots.isLoading)
    return (
      <div className="flex flex-col w-full items-center">
        <MovieInfo movieData={movieData} />
        <StyledLoader />
      </div>
    );

  const shotsData = shots.data!.pages.reduce((acc, page) => {
    return [...acc, ...page.results];
  }, [] as Shot[]);

  return (
    <div className="flex flex-col w-full flex-grow">
      <MovieInfo movieData={movieData} />
      <MovieTimeline
        totalLength={movieData.videoDuration}
        shotsStartTimes={shotsData.map((shot) => shot.startTime)}
      />
      {interfaceSetup.isVideoPlayerOpen && (
        <VideoPlayer
          shotsData={shotsData}
          setInterfaceSetup={setInterfaceSetup}
          interfaceSetup={interfaceSetup}
        />
      )}
      <Results
        shots={shots as InfiniteQueryObserverSuccessResult<SearchQuery>}
        interfaceSetup={interfaceSetup}
        shotsData={shotsData}
        setInterfaceSetup={setInterfaceSetup}
        isSpecificMovie
      />
    </div>
  );
};

export default SpecificMovieView;
