import { AuthProvider } from "authentication/AuthContext";
import ProtectedRoute from "authentication/ProtectedRoute";
import { Content, Shot } from "components/Content/Content";
import Login from "components/Login/Login";
import SpecificMovieView from "components/SpecificMovie/SpecificMovieView";
import PageWrapper from "components/wrappers/PageWrapper";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "theme";


export enum Panels {
  AdvancedFilters = "AdvancedFilters",
  Info = "Info",
}

interface AdvancedFilters {
  yearRange: { value: [number, number]; isEnabled: boolean };
  color: {
    value: { r: number; g: number; b: number };
    isEnabled: boolean;
  };
  shotType: { value: string; isEnabled: boolean };
  numberOfPeople: { value: string; isEnabled: boolean };
  dialog: { value: string; isEnabled: boolean };
  brightness: { value: number; isEnabled: boolean };
}

export interface SearchFilters {
  searchText: string;
  advancedFilters: AdvancedFilters;
  searchFile: File | null;
  // refetch on image change doesn't work properly when only listening
  // to a File type, so we have to add the filename as well
  searchFileName: string;
  movieId: string;
}

export const searchFiltersInitialState: SearchFilters = {
  searchText: "",
  advancedFilters: {
    yearRange: { value: [1900, 2023], isEnabled: false },
    color: { value: { r: 255, g: 255, b: 255 }, isEnabled: false },
    shotType: { value: "", isEnabled: false },
    numberOfPeople: { value: "", isEnabled: false },
    dialog: { value: "", isEnabled: false },
    brightness: { value: 0, isEnabled: false },
  },
  searchFile: null,
  searchFileName: "",
  movieId: "",
};

export interface InterfaceSetup {
  isVideoPlayerOpen: boolean;
  selectedShot: Shot;
  openPanel: Panels | null;
  thumbnailSize: number;
}

export const interfaceSetupInitialState = {
  isVideoPlayerOpen: false,
  selectedShot: {
    shotId: "",
    url1: "",
    url2: "",
    url3: "",
    shotUrl: "",
    videoId: "",
    videoTitle: "",
    year: "",
    director: "",
    startTime: 0,
  },
  openPanel: null,
  thumbnailSize: 50,
};

const App: React.FC = () => {
  const [searchFilters, setSearchFilters] = useState<SearchFilters>(
    searchFiltersInitialState
  );
  const [interfaceSetup, setInterfaceSetup] = useState<InterfaceSetup>(
    interfaceSetupInitialState
  );
  return (
    <ThemeProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className="text-center bg-black font-inter m-0 h-full w-full overflow-hidden flex flex-col">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <PageWrapper
                      searchFilters={searchFilters}
                      setSearchFilters={setSearchFilters}
                      interfaceSetup={interfaceSetup}
                      setInterfaceSetup={setInterfaceSetup}
                    >
                      <Content
                        searchFilters={searchFilters}
                        interfaceSetup={interfaceSetup}
                        setInterfaceSetup={setInterfaceSetup}
                        setSearchFilters={setSearchFilters}
                      />
                    </PageWrapper>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/movie/:videoId"
                element={
                  <ProtectedRoute>
                    <PageWrapper
                      searchFilters={searchFilters}
                      setSearchFilters={setSearchFilters}
                      interfaceSetup={interfaceSetup}
                      setInterfaceSetup={setInterfaceSetup}
                    >
                      <SpecificMovieView
                        searchFilters={searchFilters}
                        setSearchFilters={setSearchFilters}
                        interfaceSetup={interfaceSetup}
                        setInterfaceSetup={setInterfaceSetup}
                      />
                    </PageWrapper>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
