import { Tooltip } from "@mui/material";
import { InterfaceSetup, Panels, interfaceSetupInitialState } from "App";
import api from "api";
import { Shot } from "components/Content/Content";
import AutoPlay from "components/Content/Results/VideoPlayer/AutoPlay";
import DownloadIcon from "components/Content/Results/VideoPlayer/DownloadIcon";
import {
  StyledInput,
  StyledVolumeInput,
} from "components/Content/Results/VideoPlayer/VideoPlayer.styled";
import { useContext, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import { ThemeContext } from "theme";

interface VideoPlayerProps {
  shotsData: Shot[];
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
  interfaceSetup: InterfaceSetup;
}

export const VideoPlayer = ({
  shotsData,
  interfaceSetup,
  setInterfaceSetup,
}: VideoPlayerProps) => {
  const { mainColor } = useContext(ThemeContext);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.8);
  const [autoplay, setAutoplay] = useState(false);

  const shotIndex = shotsData.findIndex(
    (s) => s.shotId === interfaceSetup.selectedShot.shotId
  );

  useEffect(() => {
    const video = videoRef.current;
    const handleTimeUpdate = () => {
      setCurrentTime(video?.currentTime || 0);
    };
    const handleLoadedMetadata = () => {
      setDuration(video?.duration || 0);
    };
    if (video) {
      video.addEventListener("timeupdate", handleTimeUpdate);
      video.addEventListener("loadedmetadata", handleLoadedMetadata);
      return () => {
        video.removeEventListener("timeupdate", handleTimeUpdate);
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      };
    }
  }, []);

  useEffect(() => {
    const handleEnded = () => {
      if (autoplay && shotIndex < shotsData.length - 1) {
        setInterfaceSetup({
          ...interfaceSetup,
          selectedShot: shotsData[shotIndex + 1],
        });
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    };

    const video = videoRef.current;
    if (video) {
      video.addEventListener("ended", handleEnded);

      return () => {
        video.removeEventListener("ended", handleEnded);
      };
    }
  }, [autoplay, shotIndex, shotsData, interfaceSetup]);

  // this sets the playing icon when changing shot source (clicking on thumbnail)
  useEffect(() => {
    if (duration !== 0) setIsPlaying(true);
  }, [duration]);

  const togglePlay = () => {
    const video = videoRef.current;
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSeek = (time: number) => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = time;
    }
  };

  const handleVolumeChange = (value: number) => {
    const video = videoRef.current;
    if (video) {
      video.volume = value;
      setVolume(value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const renderVolumeBars = () => {
    const totalBars = 10;
    const filledBars = Math.round(volume * totalBars);

    return Array.from({ length: totalBars }).map((_, index) => (
      <div
        key={index}
        className={`w-[1px] h-[9px]  ${
          index < filledBars ? "bg-white" : "bg-slate-600"
        }`}
      ></div>
    ));
  };

  const toggleFullscreen = () => {
    const video = videoRef.current;

    if (video) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        video.requestFullscreen().catch((error) => {
          console.error("Fullscreen request failed:", error);
        });
      }
    }
  };

  const toggleAutoplay = () => {
    setAutoplay(!autoplay);
  };

  const downloadVideo = async () => {
    try {
      const response = await api.get("/download", {
        params: { shotId: interfaceSetup.selectedShot.shotId },
        responseType: "blob",
      });

      const fileURL = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = fileURL;
      link.download = `${interfaceSetup.selectedShot.videoTitle.replaceAll(
        " ",
        "_"
      )}_${interfaceSetup.selectedShot.shotId}.mp4`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(fileURL);
    } catch (error) {
      console.error("Download error:", error);
    }
  };
  return (
    <Draggable handle=".handle">
      <div className="fixed top-[30%] left-[10%] z-50 min-w-[400px] min-h-[200px] max-w-[700px] outline-none border border-white border-opacity-50 shadow-lg bg-black">
        <button
          className="absolute right-1 top-1 z-10 "
          onClick={() => {
            setInterfaceSetup({
              ...interfaceSetup,
              isVideoPlayerOpen: false,
              selectedShot: interfaceSetupInitialState.selectedShot,
            });
          }}
        >
          <span className="material-symbols-outlined rounded-full hover:bg-gray-400/20 flex items-center">
            close
          </span>
        </button>
        <div>
          <video
            ref={videoRef}
            className="handle w-full h-full cursor-move"
            src={interfaceSetup.selectedShot.shotUrl.replaceAll(" ", "%20").replace("cloud.google.com", "googleapis.com")}
            loop
            autoPlay
          />
          <div className="border-t border-white p-3 flex flex-col justify-center items-center">
            <p className="text-xs">
              <Link to={"/movie/" + interfaceSetup.selectedShot.videoId}>
                <span className="font-bold underline hover:text-slate-300">{`${interfaceSetup.selectedShot.videoTitle}`}</span>
              </Link>
              <span>
                {`, ${interfaceSetup.selectedShot.director} (${interfaceSetup.selectedShot.year})`}
              </span>
            </p>

            <div className="flex flex-col items-center w-full px-4 pt-1 mt-[15px]">
              <StyledInput
                className="cursor-pointer"
                type="range"
                min={0}
                max={duration}
                value={currentTime}
                step={0.000000001}
                onChange={(e) => handleSeek(Number(e.target.value))}
                mainColor={mainColor}
              />
              {/* <div className="time-labels">
              <span>{formatTime(currentTime)}</span>
              <span>{formatTime(duration)}</span>
            </div> */}
              <div
                id="controls"
                className="relative flex justify-center items-center w-full pt-[5px]"
              >
                <div className="absolute left-0 w-fit">
                  <div className="relative flex gap-[2px] w-full h-full">
                    {renderVolumeBars()}
                  </div>
                  <StyledVolumeInput
                    className="cursor-pointer absolute top-0 left-0 bg-transparent"
                    type="range"
                    min={0}
                    max={1}
                    step={0.01}
                    value={volume}
                    onChange={(e) => handleVolumeChange(Number(e.target.value))}
                  />
                </div>
                <div id="middle buttons">
                  <button
                    onClick={() => {
                      setInterfaceSetup({
                        ...interfaceSetup,
                        selectedShot: shotsData[shotIndex - 1],
                      });
                      setIsPlaying(true);
                    }}
                    disabled={shotIndex === 0}
                  >
                    <span
                      className={`material-symbols-outlined rounded-full flex items-center ${
                        shotIndex === 0
                          ? "text-gray-600"
                          : "hover:bg-gray-400/20"
                      }`}
                    >
                      skip_previous
                    </span>
                  </button>

                  <button onClick={togglePlay}>
                    <span className="material-symbols-outlined rounded-full hover:bg-gray-400/20 flex items-center">
                      {isPlaying ? "pause" : "play_arrow"}
                    </span>
                  </button>

                  <button
                    onClick={() => {
                      setInterfaceSetup({
                        ...interfaceSetup,
                        selectedShot: shotsData[shotIndex + 1],
                      });
                      setIsPlaying(true);
                    }}
                    disabled={shotIndex === shotsData.length - 1}
                  >
                    <span
                      className={`material-symbols-outlined rounded-full flex items-center ${
                        shotIndex === shotsData.length - 1
                          ? "text-gray-600"
                          : "hover:bg-gray-400/20"
                      }`}
                    >
                      skip_next
                    </span>
                  </button>
                </div>

                <div className="absolute right-0 flex gap-2">
                  <Tooltip title="Set autoplay">
                    <button
                      className={`rounded-full hover:bg-gray-400/20 flex items-center ${
                        autoplay ? "bg-gray-700" : ""
                      }`}
                      onClick={toggleAutoplay}
                    >
                      <AutoPlay />
                    </button>
                  </Tooltip>
                  <Tooltip title="Fullscreen">
                    <button onClick={toggleFullscreen}>
                      <span className="material-symbols-outlined rounded-full hover:bg-gray-400/20 flex items-center">
                        fullscreen_exit
                      </span>
                    </button>
                  </Tooltip>
                  <Tooltip title="Download">
                    <button onClick={downloadVideo}>
                      <DownloadIcon />
                    </button>
                  </Tooltip>
                  <Tooltip title="Open movie information panel">
                    <button
                      onClick={() => {
                        setInterfaceSetup({
                          ...interfaceSetup,
                          openPanel:
                            interfaceSetup.openPanel === Panels.Info
                              ? null
                              : Panels.Info,
                        });
                      }}
                    >
                      <span className="material-symbols-outlined rounded-full hover:bg-gray-400/20 flex items-center">
                        info
                      </span>
                    </button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};
