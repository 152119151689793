import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

interface CineticSwitchProps extends SwitchProps {
  mainColor: string;
}

export const CineticSwitch = styled(Switch)<CineticSwitchProps>(
  ({ mainColor }) => ({
    padding: 0,
    width: 46,
    height: 31,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(32px)",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: mainColor,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: "#848484",
      width: 6,
      height: 17,
      borderRadius: 0,
      marginTop: 6,
      boxShadow: "none",
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: "black",
      borderRadius: 0,
      border: "1px solid #848484",
      width: 30,
      height: 15,
    },
  })
);
