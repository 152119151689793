interface TimeOfDayFilterFlatProps {
  accentColor: string;
}

const TimeOfDayFilterFlat = ({accentColor}: TimeOfDayFilterFlatProps) => (
  <svg
    width="325"
    height="184"
    viewBox="0 0 325 184"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M156.15 3.56499H155.024V11.0067H154.931L149.761 3.56499H148.653V13.0195H149.798V5.59624H149.89L155.042 13.0195H156.15V3.56499ZM166.418 8.29226C166.418 5.30078 164.664 3.43572 162.245 3.43572C159.826 3.43572 158.072 5.30078 158.072 8.29226C158.072 11.2837 159.826 13.1488 162.245 13.1488C164.664 13.1488 166.418 11.2837 166.418 8.29226ZM165.31 8.29226C165.31 10.7482 163.962 12.0778 162.245 12.0778C160.528 12.0778 159.18 10.7482 159.18 8.29226C159.18 5.83629 160.528 4.50675 162.245 4.50675C163.962 4.50675 165.31 5.83629 165.31 8.29226ZM176.321 8.29226C176.321 5.30078 174.566 3.43572 172.147 3.43572C169.728 3.43572 167.974 5.30078 167.974 8.29226C167.974 11.2837 169.728 13.1488 172.147 13.1488C174.566 13.1488 176.321 11.2837 176.321 8.29226ZM175.213 8.29226C175.213 10.7482 173.865 12.0778 172.147 12.0778C170.43 12.0778 169.082 10.7482 169.082 8.29226C169.082 5.83629 170.43 4.50675 172.147 4.50675C173.865 4.50675 175.213 5.83629 175.213 8.29226ZM185.743 3.56499H184.616V11.0067H184.524L179.354 3.56499H178.246V13.0195H179.391V5.59624H179.483L184.635 13.0195H185.743V3.56499Z"
      fill="white"
    />
    <path
      d="M135.742 169.565V179.02H136.813V171.836H136.905L139.86 179.02H140.894L143.848 171.836H143.941V179.02H145.012V169.565H143.645L140.432 177.413H140.321L137.108 169.565H135.742ZM148.452 169.565H147.307V179.02H148.452V169.565ZM153.665 179.02C156.546 179.02 158.171 177.228 158.171 174.274C158.171 171.338 156.546 169.565 153.794 169.565H150.748V179.02H153.665ZM151.892 178.004V170.581H153.721C155.937 170.581 157.063 171.984 157.063 174.274C157.063 176.582 155.937 178.004 153.591 178.004H151.892ZM167.589 169.565H166.462V177.007H166.37L161.199 169.565H160.091V179.02H161.236V171.596H161.329L166.481 179.02H167.589V169.565ZM171.024 169.565H169.879V179.02H171.024V169.565ZM179.783 172.52H180.965C180.614 170.691 179.081 169.436 177.087 169.436C174.663 169.436 172.951 171.301 172.951 174.292C172.951 177.284 174.649 179.149 177.161 179.149C179.414 179.149 181.02 177.648 181.02 175.308V174.292H177.419V175.308H179.912C179.88 177.007 178.763 178.078 177.161 178.078C175.407 178.078 174.058 176.748 174.058 174.292C174.058 171.836 175.407 170.507 177.087 170.507C178.453 170.507 179.381 171.278 179.783 172.52ZM182.981 179.02H184.126V174.791H189.167V179.02H190.312V169.565H189.167V173.775H184.126V169.565H182.981V179.02ZM192.087 170.581H195.06V179.02H196.205V170.581H199.178V169.565H192.087V170.581Z"
      fill="white"
    />
    <path
      d="M25.9956 84.3892V93.8438H27.0667V86.6605H27.159L30.1135 93.8438H31.1476L34.1022 86.6605H34.1945V93.8438H35.2655V84.3892H33.8991L30.686 92.2372H30.5752L27.3621 84.3892H25.9956ZM45.5383 89.1165C45.5383 86.125 43.7841 84.2599 41.3651 84.2599C38.946 84.2599 37.1918 86.125 37.1918 89.1165C37.1918 92.108 38.946 93.973 41.3651 93.973C43.7841 93.973 45.5383 92.108 45.5383 89.1165ZM44.4304 89.1165C44.4304 91.5724 43.0824 92.902 41.3651 92.902C39.6477 92.902 38.2997 91.5724 38.2997 89.1165C38.2997 86.6605 39.6477 85.331 41.3651 85.331C43.0824 85.331 44.4304 86.6605 44.4304 89.1165ZM47.4634 93.8438H48.6083V90.1506H50.6765C50.7596 90.1506 50.8381 90.1506 50.9165 90.146L52.9109 93.8438H54.2404L52.103 89.9382C53.3079 89.5273 53.8711 88.544 53.8711 87.2884C53.8711 85.6172 52.8739 84.3892 50.658 84.3892H47.4634V93.8438ZM48.6083 89.1165V85.4048H50.6211C52.1538 85.4048 52.7447 86.1527 52.7447 87.2884C52.7447 88.424 52.1538 89.1165 50.6396 89.1165H48.6083ZM63.276 84.3892H62.1496V91.831H62.0573L56.8868 84.3892H55.7788V93.8438H56.9237V86.4205H57.0161L62.1681 93.8438H63.276V84.3892ZM66.7118 84.3892H65.5669V93.8438H66.7118V84.3892ZM76.5045 84.3892H75.3781V91.831H75.2858L70.1153 84.3892H69.0074V93.8438H70.1522V86.4205H70.2446L75.3966 93.8438H76.5045V84.3892ZM85.2585 87.3438H86.4403C86.0895 85.5156 84.5568 84.2599 82.5625 84.2599C80.1388 84.2599 78.4261 86.125 78.4261 89.1165C78.4261 92.108 80.125 93.973 82.6364 93.973C84.8892 93.973 86.4957 92.4727 86.4957 90.1321V89.1165H82.8949V90.1321H85.3878C85.3555 91.831 84.2383 92.902 82.6364 92.902C80.8821 92.902 79.5341 91.5724 79.5341 89.1165C79.5341 86.6605 80.8821 85.331 82.5625 85.331C83.929 85.331 84.8569 86.1019 85.2585 87.3438Z"
      fill="white"
    />
    <path
      d="M257.297 84.565H256.17V92.0067H256.078L250.907 84.565H249.799V94.0195H250.944V86.5962H251.037L256.189 94.0195H257.297V84.565ZM260.732 84.565H259.587V94.0195H260.732V84.565ZM269.491 87.5195H270.673C270.322 85.6914 268.789 84.4357 266.795 84.4357C264.371 84.4357 262.659 86.3008 262.659 89.2923C262.659 92.2837 264.357 94.1488 266.869 94.1488C269.122 94.1488 270.728 92.6484 270.728 90.3079V89.2923H267.127V90.3079H269.62C269.588 92.0067 268.471 93.0778 266.869 93.0778C265.115 93.0778 263.767 91.7482 263.767 89.2923C263.767 86.8363 265.115 85.5067 266.795 85.5067C268.161 85.5067 269.089 86.2777 269.491 87.5195ZM272.689 94.0195H273.834V89.7908H278.875V94.0195H280.02V84.565H278.875V88.7752H273.834V84.565H272.689V94.0195ZM281.795 85.5806H284.768V94.0195H285.913V85.5806H288.886V84.565H281.795V85.5806Z"
      fill="white"
    />
    <circle cx="167.788" cy="89.665" r="57.7353" stroke="white" />
    <rect
      width="1"
      height="53"
      transform="matrix(-1 0 0 1 166.787 37.0195)"
      fill={accentColor}
    />
    <rect
      width="1"
      height="8"
      transform="matrix(-1 0 0 1 166.787 18.0195)"
      fill="white"
    />
    <rect
      width="1"
      height="8"
      transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 241.787 90.0195)"
      fill="white"
    />
    <rect
      width="1"
      height="8"
      transform="matrix(1.19249e-08 -1 -1 -1.19249e-08 101.787 90.0195)"
      fill="white"
    />
  </svg>
);

export default TimeOfDayFilterFlat;
