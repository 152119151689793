export const CamcoderIcon = () => (
  <svg
    width="14"
    height="11"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4341 1.99082L8.9635 0H4.13828L2.66771 1.99082H0V10.4828H13.1034V1.99082H10.4341ZM6.55256 8.46751C5.05864 8.46751 3.84817 7.20079 3.84817 5.63745C3.84817 4.07411 5.05864 2.80738 6.55256 2.80738C8.04647 2.80738 9.25695 4.07411 9.25695 5.63745C9.25695 7.20079 8.04647 8.46751 6.55256 8.46751Z"
      fill="#F7F7F7"
    />
  </svg>
);
