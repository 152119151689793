export const PlayIcon = () => (
  <svg
    width="9"
    height="12"
    viewBox="0 0 9 12"
    fill="#707070"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 6L4.64275e-07 11.1962L9.18537e-07 0.803847L9 6Z" fill="#707070" />
  </svg>
);
