export function toCamelCase(input: any): any {
  if (typeof input !== "object" || input === null) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((item) => toCamelCase(item));
  }

  const camelCaseObject: any = {};

  for (const key in input) {
    if (Object.prototype.hasOwnProperty.call(input, key)) {
      const camelCaseKey = key.replace(/_([a-z])/g, (match, p1) =>
        p1.toUpperCase()
      );
      camelCaseObject[camelCaseKey] = toCamelCase(input[key]);
    }
  }

  return camelCaseObject;
}
