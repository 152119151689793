// @ts-nocheck

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

const isArray = function (a) {
  return Array.isArray(a);
};

const isObject = function (o) {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const keysToCamel = function (o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

export const getClientNameFromSubdomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts[0];
};

export const secondsToHHhMM = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}h${minutes}`;
};

export function convertTimestampToHHMMSS(timestamp) {
  let hours = Math.floor(timestamp / 3600); // 3600 seconds in an hour
  let minutes = Math.floor((timestamp % 3600) / 60); // Remainder from hours divided by 60
  let seconds = Math.floor(timestamp % 60); // Remainder from minutes

  // Adding leading zeros if the number is less than 10
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
}
