const EmptyRectangleIcon = () => (
  <svg
    width="338"
    height="33"
    viewBox="0 0 338 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.816406"
      y="0.691528"
      width="336.749"
      height="31.6086"
      fill="#030407"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default EmptyRectangleIcon;
