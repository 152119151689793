import { SearchFilters } from "App";
import AdvancedFilterWrapper from "components/Content/AdvancedFilters/AdvancedFilterWrapper";
import CineticRangeSlider from "components/DesignSystem/CineticRangeSlider";

interface YearFilterProps {
  setSearchFilters: (newValue: SearchFilters) => void;
  searchFilters: SearchFilters;
}

const YearFilter = ({ setSearchFilters, searchFilters }: YearFilterProps) => {
  const minDistance = 1;

  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setSearchFilters({
        ...searchFilters,
        advancedFilters: {
          ...searchFilters.advancedFilters,
          yearRange: {
            isEnabled: newValue[0] !== 1900 || newValue[1] !== 2023,
            value: [
              Math.min(
                newValue[0],
                searchFilters.advancedFilters.yearRange.value[1] - minDistance
              ),
              searchFilters.advancedFilters.yearRange.value[1],
            ],
          },
        },
      });
    } else {
      setSearchFilters({
        ...searchFilters,
        advancedFilters: {
          ...searchFilters.advancedFilters,
          yearRange: {
            isEnabled: newValue[0] !== 1900 || newValue[1] !== 2023,
            value: [
              searchFilters.advancedFilters.yearRange.value[0],
              Math.max(
                newValue[1],
                searchFilters.advancedFilters.yearRange.value[0] + minDistance
              ),
            ],
          },
        },
      });
    }
  };
  const reset = () => {
    setSearchFilters({
      ...searchFilters,
      advancedFilters: {
        ...searchFilters.advancedFilters,
        yearRange: { isEnabled: false, value: [1900, 2023] },
      },
    });
  };
  const info =
    "MOVIE YEAR REPRESENTS THE DATE OF RELEASED OR PREMIERE OF A MOVIE SCENE. THIS MEANS WHEN THE MOVIE IS RELEASED FOR THE PUBLIC TO SEE. IT MAY BE IN THEATERS OR FOR HOME VIEWING";

  return (
    <AdvancedFilterWrapper reset={reset} info={info} title="MOVIE YEAR">
      <div className="flex flex-col px-5 pt-5 w-full h-full">
        <CineticRangeSlider
          value={searchFilters.advancedFilters.yearRange.value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          disableSwap
          step={1}
          min={1900}
          max={2023}
        />
        <p className="flex justify-between text-[#848484]">
          <span>1900</span>
          <span>2023</span>
        </p>
      </div>
    </AdvancedFilterWrapper>
  );
};

export default YearFilter;
