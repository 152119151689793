import { QueryObserverSuccessResult } from "@tanstack/react-query";
import { Info } from "components/Content/Content";
import { StyledLoader } from "components/Content/Content.styled";
import LongTextField from "components/Content/InfoPanel/LongTextField";
import Timeline from "components/Content/InfoPanel/Timeline/Timeline";
import { Link } from "react-router-dom";

interface InfoPanelProps {
  videoId: string | undefined;
  info: QueryObserverSuccessResult<Info> | undefined;
  onClose: () => void | undefined;
  isOpen: boolean;
}

const formatInfo = (infoData: Info) => {
  const stringified = (Object.keys(infoData) as (keyof Info)[]).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: Array.isArray(infoData[curr])
        ? infoData[curr].join(", ")
        : infoData[curr],
    }),
    {} as Info
  );
  return {
    ...stringified,
    formattedDuration: stringified.videoDuration
      ?.slice(0, -3)
      .replace(":", "h"),
    countries: stringified.countries?.split(",")[0].toUpperCase(),
  };
};

export const InfoPanel = ({
  videoId,
  info,
  onClose,
  isOpen,
}: InfoPanelProps) => {
  const data = info?.data ? formatInfo(info.data) : null;

  return (
    <div
      className={`transition-width-min-width duration-300 ease-in-out ${
        isOpen ? "min-w-[401px] max-w-[401px] w-[401px]" : "w-0 min-w-0 max-w-0"
      }  overflow-auto relative border-l border-white  bg-black h-full`}
    >
      {!isOpen || info?.isFetching ? (
        <StyledLoader className={"self-center"} />
      ) : (
        <div className="p-[15px] flex flex-col justify-start items-start">
          <button
            className="absolute right-1 top-1 z-10 "
            onClick={() => onClose()}
          >
            <span className="material-symbols-outlined rounded-full hover:bg-gray-400/20 flex items-center">
              close
            </span>
          </button>
          <p className="text-xs font-bold text-gray-500">INFO SCENE</p>
          <Link to={"/movie/" + videoId}>
            <p className="text-[30px] text-white mt-[2.56px] underline hover:text-slate-300">
              {data!.title}
            </p>
          </Link>
          <p className="text-white my-[2px] text-base">
            {data!.formattedDuration && data!.formattedDuration + ','} {data!.countries && data!.countries + ','} {data!.year}
          </p>
          <div className="h-[1px] w-[98%] bg-[#444444] mb-[10px]" />
          <Timeline percentage={data!.positionPercent} />
          <div className="flex flex-col text-white mt-[20px] mb-[2px] text-left">
            <div className="flex flex-wrap">
              <p>
                <span className="text-[#848484]">GENRES: </span>
                <span>{data!.genres || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">DIRECTOR: </span>
                <span>{data!.director || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">CINEMATOGRAPHER: </span>
                <span>{data!.cinematographer || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">EDITOR: </span>
                <span>{data!.editor || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">PRODUCTION MANAGER: </span>
                <span>{data!.productionManager || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">COSTUME DESIGNER: </span>
                <span>{data!.costumeDesigner || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">CAST: </span>
                <LongTextField text={data!.cast || "Unknown"} />
                <br />
                <br />
                <span className="text-[#848484]">SHOT START TIME: </span>
                <span>{data!.startTimecode || "Unknown"}</span>
                <br />
                <span className="text-[#848484]">RATIO: </span>
                <span>Not available</span>
                <br />
                <span className="text-[#848484]">SHOT DURATION: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">INTERIOR/EXTERIOR: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">TIME OF DAY: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">TIME PERIOD: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">STORY LOCATION: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">FILMING LOCATION: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">COLOR PALETTE: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">DOMINANT COLOR: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">BRIGHTNESS: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">TEMPERATURE: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">TYPE OF SHOT: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">CAMERA MOVEMENT: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">COMPOSITION: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">NUMBER OF PEOPLE: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">DIALOGUE: </span>
                <span>Not available</span> <br />
                <span className="text-[#848484]">TAGS: </span>
                <span>Not available</span>{" "}
              </p>
            </div>
          </div>
          <button
            className={
              "border-[0.5px] border-gray-500 px-[8px] py-[5px] text-[12px] mt-[10px] text-[#848484]"
            }
          >
            EDIT DATA
          </button>
        </div>
      )}
    </div>
  );
};
