import { useQuery } from "@tanstack/react-query";
import { InterfaceSetup } from "App";
import api from "api";
import { Info } from "components/Content/Content";
import snakecaseKeys from "snakecase-keys";

export const useShotInfo = (interfaceSetup: InterfaceSetup) => {
  return useQuery({
    queryKey: ["shotInfo", interfaceSetup.selectedShot],
    queryFn: (): Promise<Info> =>
      api
        .get("/shot", {
          params: snakecaseKeys({
            videoId: interfaceSetup.selectedShot.videoId,
            shotId: interfaceSetup.selectedShot.shotId,
          }),
        })
        .then((res) => res.data),
    refetchOnWindowFocus: false,
    enabled: !!interfaceSetup.selectedShot.videoId,
  });
};
