import { convertTimestampToHHMMSS } from "utils";

interface MovieTimelineProps {
  totalLength: number;
  shotsStartTimes: number[];
}

const MovieTimeline = ({
  totalLength,
  shotsStartTimes,
}: MovieTimelineProps) => {
  const calculatePercentage = (
    shotStartTime: number,
    totalLength: number
  ): number => {
    const percentage = (shotStartTime / totalLength) * 100;
    return percentage > 100 ? 100 : percentage;
  };
  return (
    <div className="flex flex-col gap-2 p-4">
      <div className="flex justify-between">
        <p>00:00:00</p>
        <p>{convertTimestampToHHMMSS(totalLength)}</p>
      </div>
      <div className="relative w-full h-[30px] border">
        {shotsStartTimes.map((shotStartTime, i) => (
          <div
            key={i}
            className="absolute h-full w-[1px] bg-white"
            style={{
              left: `${calculatePercentage(shotStartTime, totalLength)}%`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default MovieTimeline;
