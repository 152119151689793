import {
  InfiniteQueryObserverSuccessResult,
  QueryObserverSuccessResult,
} from "@tanstack/react-query";
import {
  InterfaceSetup,
  Panels,
  SearchFilters,
  interfaceSetupInitialState,
  searchFiltersInitialState,
} from "App";
import { StyledLoader } from "components/Content/Content.styled";
import { InfoPanel } from "components/Content/InfoPanel/InfoPanel";
import { Results } from "components/Content/Results/Results";
import { VideoPlayer } from "components/Content/Results/VideoPlayer/VideoPlayer";
import { useSearch } from "queries/useSearch";
import { useShotInfo } from "queries/useShotInfo";
import { useEffect, useState } from "react";

export interface ContentProps {
  searchFilters: SearchFilters;
  interfaceSetup: InterfaceSetup;
  setInterfaceSetup: (newValue: InterfaceSetup) => void;
  setSearchFilters: (newValue: SearchFilters) => void;
}

export interface Info {
  akas: string[];
  aspectRatio: string;
  cast: string;
  cinematographer: string;
  colorInfo: string;
  composer: string;
  costumeDesigner: string;
  countries: string;
  countryCodes: string;
  coverUrl: string;
  director: string;
  editor: any;
  fullSizeCoverUrl: string;
  genres: string[];
  kind: string;
  languageCodes: string;
  languages: string;
  lengthSeconds: number;
  originalAirDate: string;
  originalTitle: string;
  plot: any;
  plotOutline: string;
  positionPercent: number;
  producer: string;
  productionCompanies: string;
  productionManager: any;
  rating: string;
  shotId: string;
  startTimeSeconds: number;
  startTimecode: string;
  title: string;
  top250Rank: string;
  videoDuration: string;
  videoId: string;
  videoId1: string;
  votes: string;
  writer: string;
  year: string;
}

export interface Shot {
  shotId: string;
  url1: string;
  url2: string;
  url3: string;
  shotUrl: string;
  videoId: string;
  videoTitle: string;
  year: string;
  director: string;
  startTime: number;
}

export interface SearchQuery {
  countMovies: number;
  countShots: number;
  nextPage: number;
  results: Shot[];
}

export const Content = ({
  searchFilters,
  interfaceSetup,
  setInterfaceSetup,
  setSearchFilters,
}: ContentProps) => {
  const [debouncedSearchFilters, setDebouncedSearchFilters] =
    useState<SearchFilters>(searchFilters);

  useEffect(() => {
    setInterfaceSetup({ ...interfaceSetupInitialState });
    setSearchFilters(searchFiltersInitialState);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchFilters(searchFilters);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchFilters]);

  const shots = useSearch(debouncedSearchFilters);
  const shotInfo = useShotInfo(interfaceSetup);

  if (shots.isLoading)
    return (
      <div className="flex justify-center text-2xl text-white pt-[150px] flex-grow">
        <StyledLoader />
      </div>
    );

  const shotsData = shots.data!.pages.reduce((acc, page) => {
    return [...acc, ...page.results];
  }, [] as Shot[]);

  return (
    <div className="flex justify-start items-center text-white bg-black flex-grow">
      {interfaceSetup.isVideoPlayerOpen && (
        <VideoPlayer
          shotsData={shotsData}
          setInterfaceSetup={setInterfaceSetup}
          interfaceSetup={interfaceSetup}
        />
      )}
      <Results
        shots={shots as InfiniteQueryObserverSuccessResult<SearchQuery>}
        interfaceSetup={interfaceSetup}
        shotsData={shotsData}
        setInterfaceSetup={setInterfaceSetup}
      />
      {interfaceSetup.selectedShot.videoId && (
        <InfoPanel
          videoId={interfaceSetup.selectedShot.videoId}
          info={shotInfo as QueryObserverSuccessResult<Info>}
          onClose={() =>
            setInterfaceSetup({ ...interfaceSetup, openPanel: null })
          }
          isOpen={interfaceSetup.openPanel === Panels.Info}
        />
      )}
    </div>
  );
};
