const SpeechBubleIcon = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 17.5654V2.2544V1.5H19V17.5654H16.1423V19.5L14.1633 17.5654H1Z"
      stroke="#F9F9F9"
      strokeWidth="0.5"
    />
  </svg>
);

export default SpeechBubleIcon;
