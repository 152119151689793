import axios from "axios";
import { getClientNameFromSubdomain } from "utils";
import { toCamelCase } from "utils/case";

function getBaseUrl() {
  const clientName = getClientNameFromSubdomain();
  console.log("client:", clientName);
  if (process.env.REACT_APP_ENV === "production") {
    if (clientName === "staging")
      return "https://cinetic-api-stg-yng57fzlzq-od.a.run.app";
    else if (clientName === "app")
      return "https://cinetic-api-yng57fzlzq-od.a.run.app";
    else if (clientName === "mk2")
      return "https://cinetic-api-zywg5aqcva-ew.a.run.app";
    else if (clientName === "bologna")
      return "https://cinetic-api-3l55qokn2q-ew.a.run.app";
    else if (clientName === "studiocanal")
      return "https://backend-api-7rufnqotja-uc.a.run.app";
  } else {
    return "http://localhost:8082";
  }
}

export const BASE_URL = getBaseUrl();

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.response.use((response) => {
  if (response.headers["content-type"] === "application/json")
    response.data = toCamelCase(response.data);
  return response;
});

export default api;
